import React, { useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../components/HeaderMenu.comp'
import LoginContext from '../../context/login_context/LoginContext';
import { GetLastDataInsert } from '../../API/GetLastDataInsert.api';
import { Chart_bars } from '../../chart/Chart_bars';

function Main() {
  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
  // console.log(LoginState.planta)

  const [LastInseretData, setLastInseretData] = useState('');
  const [VisibleModal, setVisibleModal] = useState(false);

  useEffect(() => {
    const queriApi = async () => {
      const data = await GetLastDataInsert({planta: LoginState.planta})
      setLastInseretData(data)
    }
    queriApi()
  
    return () => {
      
    }
  }, [])
  

  return (
    <>
      <HeaderMenu Pag_Sel={'dashboard'} VisibleModal={VisibleModal} setVisibleModal={setVisibleModal} />
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <p className='badge badge-info p-2'>Fecha Ultimo dato ingresado: {LastInseretData}</p>
          </div>
          <div className='col-12 p-0 m-0'>
            <div className='row p-0 m-0'>
              <div className='col-12 col-md-6'>
                <Chart_bars empresa={LoginState.planta} chartNumber={1} />
              </div>
              <div className='col-12 col-md-6'>
                <Chart_bars empresa={LoginState.planta} chartNumber={2} />
              </div>
            </div>
          </div>
          <br/>
        </div>
      </div>
    </>
  )
}

export default Main