import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";
import SettingsIcon from '@mui/icons-material/Settings';
import { useTheme } from '@mui/material/styles';
import './main.css';
import { GetListMedidores } from '../API/GetListMedidores.api';
import { Box, Button, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { GetDatoToShowChart } from '../API/GetDatoToShowChart.api';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


export const Chart_bars = ({empresa,chartNumber}) => {
  const theme = useTheme();
  // console.log(empresa,chartNumber)
  const [IsModal, setIsModal] = useState(false)
  const [ListMedidores, setListMedidores] = useState([])
  const [MedidorSelect, setMedidorSelect] = useState([])
  const [TitleChart, setTitleChart] = useState('')
  const [Serie, setSerie] = useState([])
  const [Categories, setCategories] = useState([])


  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMedidorSelect(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const SaveOnLocalStorageDataChart = async () => {
    // console.log(MedidorSelect)
    localStorage.setItem(chartNumber,MedidorSelect)
    localStorage.setItem('title'+chartNumber,TitleChart)
    localStorage.setItem('EMPRESA',empresa)

    LoadDatoToShowChart(MedidorSelect)
    setIsModal(!IsModal)
  }

  const LoadDatoToShowChart = async (data) => {
    let chartData = await GetDatoToShowChart({data,empresa});
    setSerie(chartData.series)
    setCategories(chartData.categories)
  }


  useEffect(() => {
    const GetListMedidores_ = async () => {
      const listMedidores_ = await GetListMedidores({empresa})
      setListMedidores(listMedidores_)
    }
    GetListMedidores_()
  
    return () => {
      
    }
  }, [])

  useEffect(() => {
    let empresa_saved = localStorage.getItem('EMPRESA');
    if(empresa_saved !== empresa){
      localStorage.clear()
    }else{
      let LocalChartData = localStorage.getItem(chartNumber)
      let titulo = localStorage.getItem('title'+chartNumber) || ''
      if(LocalChartData){
        LoadDatoToShowChart(LocalChartData.split(','))
        setMedidorSelect(LocalChartData.split(','))
        setTitleChart(titulo)
      }
    }
  
    return () => {
      
    }
  }, [])

  let state= {
    series: Serie,
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: false,//divide la columna en la cantidad de series
      },
      noData: {
        text: "Seleccione el icono ⚙ sobre el grafico para modificar.",
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '14px',
          fontFamily: undefined
        }
      },
      // colors: ['#FCAC00', '#66DA26', '#B8FC00', '#1BFC00', '#00FC6F', '#00FCF1', '#8900FC'],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%',
          barHeight: '70%',
          endingShape: 'rounded',
          borderRadius: 5,
          isDumbbell: true,
          dumbbellColors: [['#008FFB', '#00E396']]
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 3,
        colors: ['white']
      },
      xaxis: {
        categories: Categories
      },
      yaxis: {
        title: {
          text: 'Lecturas'
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: "vertical",
          shadeIntensity: 0.5,
          gradientToColors: undefined,
          opacityFrom: 1,
          // opacityTo: 1,
          inverseColors: false,
          stops: [0, 100]
        },
      },
      title: {
        text: TitleChart,
        align: 'center',
        style: {
          fontSize:  '14px',
          fontWeight:  200,
          fontFamily: 'tahoma',
          color:  '#07218A'
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return " " + val + " "
          }
        }
      }
    },
  }
  

  return (
    <div className='col p-0 mb-3' style={{height:"70vh", }}>
      <label className='btn-settng-mdl'
        onClick={()=>setIsModal(!IsModal)}
      ><SettingsIcon color={IsModal?"error":"action"} /></label>
      <div className={IsModal?'modal_card_settng-show':'modal_card_settng-hidd'}>
        <div className='pt-5 pb-3'>
          <p>Seleccione los medidores a graficar.</p>
          <div className='col'>
            <TextField
              fullWidth
              label="Titulo del Grafico"
              variant="outlined"
              value={TitleChart}
              onChange={(e)=>setTitleChart(e.target.value)}
              size="small" />
          </div>
          <div className='col mt-2'>
            <FormControl size='small' fullWidth>
              <InputLabel id="demo-multiple-chip-label">Chip</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={MedidorSelect}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {ListMedidores.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, MedidorSelect, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className='col mt-2'>
            <Button
              variant='contained'
              size='small'
              onClick={()=> SaveOnLocalStorageDataChart()}
            >Guardar Cambios</Button>
          </div>
          
          
          
        </div>
      </div>
      <Chart
        
        options={state.options || null}
        series={state.series || null}
        type="bar"
        width={'100%'}
        height={'100%'}
      />
    </div>
  )
}
