import React from 'react'
import { URL_SRV_RAIZ } from '../router/Url';

const MakeMeAFilePLZ = ({YEAR, empresa}) => {
  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_SRV_RAIZ+'make_me_a_file',{
      method: 'POST',
      headers: {
        'authorization': "paico2021",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        YEAR: YEAR,
        empresa: empresa
      })
    })
    let responsito = await query.json();
    // console.log(responsito)
    if(responsito.success){
      let url = responsito.body;
      let a = document.createElement("a");
      a.href = URL_SRV_RAIZ+url;//to web add url_server+build/
      // console.log(a.href)
      // a.href = url;
      a.download = "Reporte_Medidores.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
      resolve(responsito.success)
      alert('En instantes se descargara su Archivo')

    }else{
      resolve(responsito.success)
      alert('ups!! Ocurrio un error vuelva a intentarlo.')
    }
  })
}

export default MakeMeAFilePLZ