import React, { useContext, useEffect, useState } from 'react'
import { GetYearsOfData } from '../../API/GetYearsOfData.api';
import LoginContext from '../../context/login_context/LoginContext';
import MakeMeAFilePLZ from '../../API/MakeMeAFilePLZ.api';

const ModalMakeMeAFile = ({VisibleModal, setVisibleModal}) => {
  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);

  const [ListYYYY, setListYYYY] = useState([]);
  const [SelectYYYY, setSelectYYYY] = useState('0');
  const [IsdisabledButton, setIsdisabledButton] = useState(true);

  const SendToMakeMyFile = async () => {
    let seHizoElFile = await MakeMeAFilePLZ({ YEAR: SelectYYYY,empresa:LoginState.planta })
    if(seHizoElFile)setVisibleModal(!VisibleModal)
    else setVisibleModal(!VisibleModal)
  }

  useEffect(() => {
    const yyyy = async () => {
      let years_ = await GetYearsOfData({empresa:LoginState.planta});
      // console.log(years_)
      await setListYYYY(years_)
    }
    yyyy()
  
    return () => {
      
    }
  }, [])

  useEffect(() => {
    if(SelectYYYY === '0' || SelectYYYY === ''){
      setIsdisabledButton(true)
    } else {
      setIsdisabledButton(false)
    } 
    return () => {
      
    }
  }, [SelectYYYY])


  return (
    <div className='container modal_mf_1'>
      <div className='row' style={{height: "90vh"}}>
        <div className='col text-center modal_mf'>
          <div className='row modal_cont text-left'>
          <div className='col-12 pb-3 pt-3 border-bottom border-secondary'>
                <label style={{zIndex:'13000000000',  position: 'relative', textShadow: '0px 0px 3px white'}}><b>Generar Reporte</b></label>

<div className="ocean_3">
  <div className="wave_3"></div>
  <div className="wave_3"></div>
  <div className="wave_3"></div>
</div>

              </div>
            <div className='col-12 my-3'>
              <label>Seleccione Año</label>
              <select
                  className="form-control form-control-sm"
                  value={SelectYYYY}
                  onChange={(e)=>setSelectYYYY(e.target.value)}
                >
                  <option value="0" defaultChecked>Seleccione Año</option>
                  {
                    ListYYYY.map((yy, index) => <option key={index} value={yy}>{yy}</option>)
                  }
                </select>
            </div>
            <div className='col-12 text-right border-top border-secondary rounded-top py-3'>
              <button
                className='btn btn-danger btn-sm'
                onClick={()=>setVisibleModal(!VisibleModal)}
              >Cancelar</button>
              <span className='ml-3'></span>
              <button 
                disabled={IsdisabledButton}
                onClick={()=>SendToMakeMyFile()}
                className='btn btn-primary btn-sm'>
                Generar Reporte
              </button>
            </div>

            
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalMakeMeAFile