import React from 'react'
import { URL_SRV_RAIZ } from '../router/Url';

export const UpdateDataLectura = ({NEWVALUE, ID}) => {
  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_SRV_RAIZ+'updatelecturamedidor',{
      method: 'POST',
      headers: {
        'authorization': "paico2021",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        NEWVALUE: NEWVALUE,
        ID: ID
      })
    })
    let responsito = await query.json();
    // console.log(responsito)
    if(responsito.data.success){
      // console.log('se deberia de haber modificado el dato')
      resolve(true)
    }else{
      resolve(false)
    }
  })
}
