import React, { useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../components/HeaderMenu.comp'
import { Button, FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material'
import LoginContext from '../../context/login_context/LoginContext';
import { GetYearsOfData } from '../../API/GetYearsOfData.api';
import GetDataToShowTableDatos from '../../API/GetDataToShowTableDatos.api';
import { FormatNumberCL } from '../../mutations/FormatNumeral.Mutation';
import { UpdateDataLectura } from '../../API/UpdateDataLectura.api';

// icons
import SearchIcon from '@mui/icons-material/Search';

export const Datos = () => {
  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
  // console.log(LoginState)
  const [HeaderDates, setHeaderDates] = useState([]);
  const [ListTable, setListTable] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [DatosToEdit, setDatosToEdit] = useState({});
  const [ModalValueNew, setModalValueNew] = useState('');
  const [IsDIsabledNewValue, setIsDIsabledNewValue] = useState(true);
  const [IsReadyToSearch, setIsReadyToSearch] = useState(true);

  const [ListYYYY, setListYYYY] = useState([]);
  const [SelectYYYY, setSelectYYYY] = useState('');
  const [SelectMM, setSelectMM] = useState('');
  const [VisibleModal, setVisibleModal] = useState(false);
  // console.log(LoginState.planta)

  const ShowModalToChangeData = async ({id, medidor, valor}) => {
    setModalValueNew('')
    setDatosToEdit({id, medidor, valor})
    setIsShowModal(true)
  }

  const apiquery = async (regFecha) => {
    let datasSW = await GetDataToShowTableDatos({empresa:LoginState.planta, MINIFECHA: regFecha})
    // console.log(datasSW)
    setHeaderDates(datasSW.FECHAS);
    setListTable(datasSW.DATA);
  }

  const ChangeDataValueLectura = async () => {
    let seGuardo = await UpdateDataLectura({NEWVALUE:ModalValueNew, ID:DatosToEdit.id})
    if(seGuardo){
      apiquery(SelectYYYY+'-'+SelectMM)
      setIsShowModal(!isShowModal)
    }
    else alert('Ocurrio un problema, vuelva a intentarlo mas tarde.')
  }

  const FinferMyDatas = async () => {
    // console.log(SelectYYYY+'-'+SelectMM)
    await apiquery(SelectYYYY+'-'+SelectMM)
  }

  useEffect(() => {
    const yyyy = async () => {
      let years_ = await GetYearsOfData({empresa:LoginState.planta});
      // console.log(years_)
      await setListYYYY(years_)
    }
    yyyy()
  
    return () => {
    }
  }, [])

  useEffect(() => {
    if(SelectMM === '' || SelectYYYY === ''){
      setIsReadyToSearch(true)
    } else {
      setIsReadyToSearch(false)
    }
  
    return () => {
      
    }
  }, [SelectMM, SelectYYYY])

  useEffect(() => {
    if(ModalValueNew.length >= 1){
      setIsDIsabledNewValue(false)
    } else {
      setIsDIsabledNewValue(true)
    }
  
    return () => {
    }
  }, [ModalValueNew])
  
  return (
    <>
      <HeaderMenu Pag_Sel={'datos'} VisibleModal={VisibleModal} setVisibleModal={setVisibleModal} />
      <div className='container mt-3'>
        <div className='row'>
          <div className='col-4'>
            <FormControl size='small' fullWidth>
              <InputLabel id="demo-simple-select-label">Año</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={SelectYYYY}
                label="Age"
                onChange={(e)=>setSelectYYYY(e.target.value)}
              >
                {/* <MenuItem value={rrs}>{rrs}</MenuItem> */}
                {
                  ListYYYY.map((rrs, index) =>
                    <MenuItem key={index} value={rrs}>{rrs}</MenuItem>
                  )
                }
              </Select>
            </FormControl>
          </div>
          <div className='col-4'>
            <FormControl size='small' fullWidth>
              <InputLabel id="demo-simple-select-label">Mes</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={SelectMM}
                label="Age"
                onChange={(e)=>setSelectMM(e.target.value)}
              >
                <MenuItem value={'01'}>Enero</MenuItem>
                <MenuItem value={'02'}>Febrero</MenuItem>
                <MenuItem value={'03'}>Marzo</MenuItem>
                <MenuItem value={'04'}>Abril</MenuItem>
                <MenuItem value={'05'}>Mayo</MenuItem>
                <MenuItem value={'06'}>Junio</MenuItem>
                <MenuItem value={'07'}>Julio</MenuItem>
                <MenuItem value={'08'}>Agosto</MenuItem>
                <MenuItem value={'09'}>Septiembre</MenuItem>
                <MenuItem value={'10'}>Octubre</MenuItem>
                <MenuItem value={'11'}>Noviembre</MenuItem>
                <MenuItem value={'12'}>Diciembre</MenuItem>
              </Select>
            </FormControl>
          </div>
            <Tooltip title=" ⬅ Seleccione primero el año y el mes para desplegar los datos en la fecha seleccionada.">
          <div className='col-4 text-right'>
              <Button
                variant="contained"
                disabled={IsReadyToSearch}
                onClick={()=> FinferMyDatas()}
              ><SearchIcon/> Buscar </Button>
          </div>
            </Tooltip>
          {/* table */}
          <div className='col-12 my-3 table-responsive-sm'>
            <table className="table table-sm table-hover" style={{border: "1px solid black"}}>
              <thead className="thead-dark">
                <tr>
                  <th scope="col"><small>Medidores</small></th>
                  {
                    HeaderDates?.map((cg, index) => <th key={index} className='text-center'><small>{cg._id}</small></th>)
                  }
                </tr>
              </thead>
              <tbody>
                {
                  ListTable?.map((png, index) => <tr key={index}><td key={index}><small>{png.medidor}</small></td>
                    {
                      png["lecturas"]?.map((png_, index) => 
                        <td onClick={LoginState.rol==="user"?null:()=>ShowModalToChangeData({id:png_.id,medidor:png.medidor, valor:png_[HeaderDates[index]._id]})} key={index} className='hover-select-table text-center'><small>{FormatNumberCL(png_[HeaderDates[index]._id])}</small></td>
                      )
                    } 
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* modal */}
      {
        isShowModal? <div className='modal_chdata'>
        <div className='modal_chdata_body'>
          <div className='container bg-card-modal p-3'>
            <div className='row'>
              <div className='col-12 pb-3 border-bottom border-secondary'>
                <label style={{zIndex:'13000000000',  position: 'relative', textShadow: '0px 0px 3px white'}}><b>{ DatosToEdit?.medidor }</b></label>

<div className="ocean_1">
  <div className="wave_1"></div>
  <div className="wave_1"></div>
  <div className="wave_1"></div>
</div>

              </div>
              <div className='col-12 col-md-6 py-3 section-modal'>
                <label className='pl-2'>Valor Actual</label>
                <input value={ FormatNumberCL(DatosToEdit?.valor) } className='form-control form-control-sm' readOnly />
              </div>
              <div className='col-12 col-md-6 py-3 section-modal'>
                <label className='pl-2'>Nuevo Valor</label>
                <input
                  type='number'
                  value={ModalValueNew}
                  className='form-control form-control-sm '
                  onChange={(e)=>setModalValueNew(e.target.value)}
                />
              </div>
              <div className='col-12 text-right border-top border-secondary pt-3'>
                <button className='btn btn-sm btn-danger'
                  onClick={()=> setIsShowModal(false)}
                >Cancelar</button>
                <span style={{marginLeft: '15px'}}></span>
                <button
                  disabled={IsDIsabledNewValue}
                  className='btn btn-sm btn-info'
                  onClick={()=>ChangeDataValueLectura()}
                >Guardar Cambios</button>
              </div>
            </div>
          </div>
        </div>
      </div>:null
      }
     
    </>
  )
}
